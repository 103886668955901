import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Cart from './cart';
import Lang from '../general/Lang';
import { LuGrip, LuLayoutGrid, LuMenu, LuStretchHorizontal } from "react-icons/lu";
import { TiThListOutline, TiThSmallOutline } from "react-icons/ti";
import { VscFeedback } from "react-icons/vsc";
import Item from '../category/item';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        slide: [],
        menus: [],
        loadMenus: false,
        selectedMenu: 0,
        loadSlide: false,
        loadCat: false,
        load: false,
        listview: "two",
        cart: false,
        isCategoryVisible: false,
        lastScrollTop: 0,
        menuId: 0,
    }

    async componentDidMount(){

        const pathSegments = window.location.pathname.replace("/menu/", "").split('/');
        const lastTwoValues = pathSegments.slice(-2);

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/menuCategories/swisslandgenev/" + lastTwoValues[0]);
        this.setState({ categories: data }, ()=>{
            this.setState({loadCat: true, menuId: lastTwoValues[0]});
            this.getCategoryName(lastTwoValues[1]);
        });

        {
            // Get Slideshows
            const {data} = await axios.get("https://manage.caviarmenu.com/api/slideshow/swisslandgenev");
            this.setState({ slide: data }, ()=>{
                this.setState({loadSlide: true});
            });
        }

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/groups/swisslandgenev");
            this.setState({ menus: data }, () => {
                const foundIndex = data.findIndex(menu => menu.id ==  lastTwoValues[0]);
                this.setState({ loadMenus: true, selectedMenu: foundIndex });
            });
        }

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/menuItems/swisslandgenev/" + lastTwoValues[0]);
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }
        this.layoutDirection();
    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        this.state.categories.map(cat => {
            if(cat.id == id) this.setState({ selectCategory: cat });
         });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});
        this.setState({isCategoryVisible: false});
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    handleCartClose = close => {
        this.setState({cart: close});
    }

    handleCategoryClose = close => {
        this.setState({isCategoryVisible: close});
    }

    handleMenuClick = e => {
        this.setState({loadMenus: false});
        this.setState({selectedMenu: e}, () => {
            this.setState({loadMenus: true});
        });
    }

    render() {

        const container = window !== undefined ? () => window.document.body : undefined;
        const drawerBleeding = 56;

        return (
            <div className='menu-items' onScroll={this.handleScroll}>
                
                <div className="head">

                    <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <VscFeedback /> <span>Feedback</span> 
                        </Link>
                    </div>

                    <div className="language">
                        <Lang handleLangaugeSubmit = {this.props.handleLangaugeSubmit} ></Lang>
                    </div>
                    
                    
                    <div className='carousel'>
                        {
                            this.state.loadSlide 
                            ? 
                                <Carousel autoPlay={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true}>
                                    { this.state.slide.map(slide=> <div key={slide.id}><img src={slide.image}/></div>)}
                                </Carousel>
                            : 
                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#135B3C">
                                <Skeleton height={180} />
                            </SkeletonTheme>
                        }
                        
                    </div>
                    
                </div>

                <div className="head-action">

                    <div>
                        <Link to={"/category"}>
                            <img src={backArrow} className="backward" alt="" />
                        </Link>
                    </div>

                    <div>

                        <div className="head-listview">
                            <div className="listview">
                                <LuLayoutGrid className={`fas fa-th-large ${this.state.listview == "two"?'active':''}`} onClick={ () => this.handleListview("two")} />
                                <TiThListOutline className={`fa-solid fa-list-ul ${this.state.listview == "list"?'active':''}`} onClick={ () => this.handleListview("list")} />
                            </div>
                            
                            <h5 className="category-name">
                                {
                                    this.props.Language == "en" ? this.state.selectCategory.name_eng
                                    : this.props.Language == "krd" ? this.state.selectCategory.name_krd
                                    : this.state.selectCategory.name_ar
                                }
                            </h5>
                            
                        </div>
                        
                    </div>
                    <div>
                        <button className='cartButton' onClick={()=> this.handleCartClose(true)}>
                            <i className="fa-solid fa-cart-shopping"></i>
                            {
                                this.props.SumQty? 
                                <span className='cartQty'> { this.props.SumQty } </span> 
                                : ""
                            }
                        </button>
                    </div>

                </div>

                

                <div className='items' id='itemContainer' style={ {'direction': this.props.Language != "en"? "rtl":"ltr"} }>
                <PhotoProvider maskOpacity={0.7}>
                

                {
                    this.state.load ?
                    <> 
                        {
                        this.state.selectCategory.subCat 
                        ? 
                            <div className="subcategories">
                                {
                                    this.state.selectCategory.subCategories.map(
                                        item => <Subcategory 
                                                                key={item.id} 
                                                                Language = {this.props.Language} 
                                                                ListView = {this.state.listview} 
                                                                Category = {item} 
                                                                Items = {this.state.items[item.id]} />
                                    )
                                }
                            </div>
                        :
                        this.state.listview == "list" ? 
                            this.state.items[this.state.selectCategory.id].map(item => 
                                    <List key={item.id}  
                                            Data = {item}
                                            addCartItem = {this.props.addCartItem} 
                                            Language = {this.props.Language} >
                                    </List> )

                            : this.state.listview == "two" ? 
                                <div className='style-two'> { 
                                        this.state.items[this.state.selectCategory.id].map( (item, index) => 
                                            <Two key={item.id}  
                                                    Data = {item} 
                                                    Language = {this.props.Language}
                                                    addCartItem = {this.props.addCartItem} 
                                                    Index={index} 
                                                    Length={ this.state.items[this.state.selectCategory.id].length } >
                                            </Two> ) } </div>

                            : <div className='style-large'> 
                                { 
                                    this.state.items[this.state.selectCategory.id].map((item, index) => 
                                        <Large key={item.id}
                                                Data = {item}
                                                Cart={this.props.Cart}
                                                Language = {this.props.Language} 
                                                addCartItem = {this.props.addCartItem} 
                                            ></Large> ) 
                                }
                            </div>
                        }
                        
                    </>
                    : 
                    <SkeletonTheme baseColor="#FFFFFF12" highlightColor="#135B3C" >
                        <Skeleton count={5} height={100} />
                    </SkeletonTheme>
                }
                    </PhotoProvider>
                </div>
                <PhotoProvider maskOpacity={0.7}>
                <Cart Cart={this.props.Cart}
                        SumPrice={this.props.SumPrice}
                        SumQty={this.props.SumQty}
                        Language = {this.props.Language} 
                        Show = {this.state.cart}
                        clearCartItem = {this.props.clearCartItem}
                        updateCartItem = {this.props.updateCartItem}
                        HandleCartClose = {this.handleCartClose} />

                </PhotoProvider>

                <div className= {`categories-container ${ this.state.isCategoryVisible ? 'categoriesShow000': ''}`}>
                    <div className={`categories`}>
                        <div className='categories-head'>
                            <i className="fa-solid fa-xmark" onClick={()=> this.handleCategoryClose(false)}></i>
                            <div>

                                {
                                    this.state.loadMenus ?
                                    <>
                                            {this.state.menus.map( (menu, index) => 

                                            <button
                                                key={index}
                                                className={`menuItem ${ (this.state.selectedMenu == index)? 'menuItemActive': ''}`}
                                                onClick={ () => this.handleMenuClick(index)}>
                                                {
                                                    this.props.Language == "en" ? menu['name_eng']
                                                    : this.props.Language == "krd" ? menu['name_krd']
                                                    : menu['name_ar']
                                                }
                                            </button>)}
                                    </> :
                                        <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#603913">
                                            <Skeleton height={50} />
                                        </SkeletonTheme>
                                }
                                
                            </div>
                        </div>
                        
                        <div className='categories-body'>
                            
                            {
                                this.state.loadMenus ?

                                <>
                                    {
                                        Object.entries(this.state.menus[this.state.selectedMenu].groups).map(([groupsKey, group]) => {

                                            return (
                                                <div key={groupsKey}>
                                                <h2 className={`category-list-head`} style={{'animation': `tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both`}}> 
                                                    {
                                                        this.props.Language == "en" ? group.name_eng
                                                        : this.props.Language == "krd" ? group.name_krd
                                                        : group.name_ar
                                                    }
                                                </h2>

                                                <div className={`categories-body-cat`} style={ {'direction': this.props.Language != "en"? "rtl":"ltr"} } >
                                                    {group.categories.map((cat, index) => (

                                                        <Category 
                                                            key={cat.id}  
                                                            Data = {cat} 
                                                            Language = {this.props.Language}
                                                            MenuId = {this.state.menus[this.state.selectedMenu].id}
                                                            handleCategory = { this.handleCategory}
                                                            Active = {this.state.selectCategory.id == cat.id} ></Category>

                                                    ))}
                                                </div>
                                                </div>
                                            );

                                        })
                                    }
                                </>
                                : 
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#603913">
                                    <Skeleton height={50} />
                                </SkeletonTheme>
                            }
                        </div>

                    </div>
                </div>


                <SwipeableDrawer
                    container={container}
                    anchor="bottom"
                    open={this.state.isCategoryVisible}
                    onClose={ () => this.setState({isCategoryVisible: false})}
                    onOpen={ () => this.setState({isCategoryVisible: true}) }
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                    keepMounted: true,
                    }}
                >
                    
                    <div className='categories-head'>

                        {
                            this.state.loadMenus ?
                            <>
                                    {this.state.menus.map( (menu, index) => 

                                    <button
                                        key={index}
                                        className={`menuItem ${ (this.state.selectedMenu == index)? 'menuItemActive': ''}`}
                                        onClick={ () => this.handleMenuClick(index)}>
                                        {
                                            this.props.Language == "en" ? menu['name_eng']
                                            : this.props.Language == "krd" ? menu['name_krd']
                                            : menu['name_ar']
                                        }
                                    </button>)}
                            </> :
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#603913">
                                    <Skeleton height={50} />
                                </SkeletonTheme>
                        }
                        
                    </div>

                    <div className='categories-body'>
                            
                            {
                                this.state.loadMenus ?

                                <>
                                    {
                                        
                                        Object.entries(this.state.menus[this.state.selectedMenu].groups).map(([groupsKey, group]) => {

                                            return (
                                                <div key={groupsKey}>
                                                <h2 className={`category-list-head`} style={{'animation': `tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both`}}> 
                                                    {
                                                        this.props.Language == "en" ? group.name_eng
                                                        : this.props.Language == "krd" ? group.name_krd
                                                        : group.name_ar
                                                    }
                                                </h2>

                                                <div className={`categories-body-cat`} style={ {'direction': this.props.Language != "en"? "rtl":"ltr"} } >
                                                    {group.categories.map((cat, index) => (

                                                        <Category 
                                                            key={cat.id}  
                                                            Data = {cat} 
                                                            Language = {this.props.Language}
                                                            MenuId = {this.state.menus[this.state.selectedMenu].id}
                                                            handleCategory = { this.handleCategory}
                                                            Active = {this.state.selectCategory.id == cat.id} ></Category>

                                                    ))}
                                                </div>
                                                </div>
                                            );

                                        })
                                    }
                                </>
                                : 
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#603913">
                                    <Skeleton height={50} />
                                </SkeletonTheme>
                            }
                        </div>

                </SwipeableDrawer>

                <button className="categoryBtn" onClick={ () => this.setState({isCategoryVisible: true})}>
                    <TiThSmallOutline />
                </button>
                
                <div className="footer">
                    Powered By <a href='https://www.instagram.com/caviar.menu/' className='caviar'>Caviar Menu</a>
                </div>
                
            </div>
        );
    }
}

export default Menu;